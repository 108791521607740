import { Injectable } from '@angular/core';
import { BehaviorSubject, shareReplay } from 'rxjs';
import { BreadCrumbItem } from '../models/breadcrumb-item.model';

@Injectable({
  providedIn: 'root',
})
export class BreadCrumbService {
  private _breadcrumbItems = new BehaviorSubject<BreadCrumbItem[]>([]);
  breadcrumbItems$ = this._breadcrumbItems.asObservable().pipe(shareReplay(1));

  publishBreadCrumbItems(breadcrumbItems: string[]) {
    const breadcrumbs = breadcrumbItems.reduce<{
      items: BreadCrumbItem[];
      url: string;
    }>(
      (acc, item) => {
        // Remove everything after and including the '?' character
        const cleanItem = item.split('?')[0];

        // Transform the segment to readable text
        // const transformedItem = this.transformSegment(cleanItem);

        // Build the new URL by appending this item
        const newUrl = `${acc.url}/${cleanItem}`;

        // Create the new breadcrumb item
        const breadcrumbItem: BreadCrumbItem = {
          text: cleanItem,
          url: newUrl,
        };

        // Return a new object with the updated items and url
        return {
          items: [...acc.items, breadcrumbItem],
          url: newUrl,
        };
      },
      { items: [], url: '' },
    ).items;

    this._breadcrumbItems.next(breadcrumbs);
  }
}
